
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import { DriverActions } from '@/store/modules/drivers/actions'
import { Driver } from '@/shared/interfaces/driver.interface'
import { debounce } from 'lodash'
import { DriverRevenueInterface } from '@/shared/interfaces/reports/driver-revenue.interface'
import { OrderActions } from '@/store/modules/orders/actions'
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  components: {
    Multiselect,
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue'))
  },
  data () {
    return {
      selectedDriver: null as any,
      showFilterModal: false,
      tableFields: [
        { id: 'orderId', name: 'Order Id', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'order_id', type: 'link' },
        { id: 'orderStatus', name: 'Order Status', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'orderCreatedAt', name: 'Order Created At', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'orderDeliveredAt', name: 'Order Delivered At', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'orderTotal', name: 'Order Total', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'username', name: 'Driver', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'driverFullName', name: 'Driver Full Name', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'customerPhoneNumber', name: 'Customer Phone Number', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'addressAddressLine1', name: 'Customer Address', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'addressBuilding', name: 'Customer Address Building', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'addressDistrict', name: 'Customer Address District', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'addressFlat', name: 'Customer Address Flat', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'addressFloor', name: 'Customer Address Floor', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'addressPhone', name: 'Customer Address Phone', width: '15%', sortable: false, currentSort: false, sort: null }
      ],
      filters: [
        { name: this.$t('filters.date'), model: 'orderCreatedAt', type: 'date', calendarType: 'range' },
        { name: this.$t('filters.orderId'), model: 'orderIds', type: 'text' },
        {
          name: this.$t('filters.orderStatus'),
          model: 'status',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'orderStatus',
          type: 'radio',
          data: []
        }
      ],
      selectedReport: this.reportType,
      handleSearch: debounce((searchText: string) => {
        if (!searchText.length) return
        this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS_AUTOCOMPLETE}`,
          {
            pageNumber: 1,
            pageSize: 1000, // we need to get all the data
            search: searchText
          }
        )
      }, 1000),
      driverSearchable: false
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    onExpandPanelClick (eventName: string): void {
      if (eventName === 'orderStatus') {
        this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_STATUSES}`)
      }
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    },
    onRedirectToOrder (item: any): void {
      router.push({ path: '/orders/all', query: { id: item.orderId } })
    }
  },
  watch: {
    selectedDriver (val) {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, driverId: this.selectedDriver.id } })
    },
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    statuses (val: any) {
      if (!val.length) return
      const filterItems = val.map((el: any) => {
        return {
          id: el.id,
          label: el.value
        }
      })
      const target = this.filters[2]
      target.dataRequested = true
      target.data = filterItems
      target.dataLoading = false
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    reports (): DriverRevenueInterface[] {
      return this.$store.getters['reports/getDriversRevenueReports']
    },
    exportReports () {
      return this.$store.getters['reports/getDriversRevenueExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getDriversRevenuePagination']
    },
    loading (): boolean {
      return this.$store.getters['reports/getLoading']
    },
    drivers (): Driver[] {
      const drivers = this.$store.getters['drivers/getDriversAutocomplete']
        .map((driver: any) => ({ name: driver.userName, id: driver.driverId }))
      if (this.selectedDriver) {
        const hasSelected = drivers.findIndex((d:any) => d.id === this.selectedDriver.id)
        return hasSelected > -1 ? drivers : [this.selectedDriver, ...drivers]
      }
      return drivers
    },
    driverLoading (): boolean {
      return this.$store.getters['drivers/getLoading']
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    }
  }
})
